import React, { Component } from 'react';
import Slider from 'react-slick';

import * as VIDEOS from '../../constants/videos';

class Library extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: props.url
		}
	}

	render () {
		const homePageOverview = VIDEOS.HOME_PAGE.map((video, index) => 
			<Tile title={video.title} description={video.description} url={video.url} img={video.img} key={index} onClick={(event) => this.props.handleTileClick(event)} />
		);

		const touringTabs = VIDEOS.TOURING_TABS.map((video, index) => 
			<Tile title={video.title} description={video.description} url={video.url} img={video.img} key={index} onClick={(event) => this.props.handleTileClick(event)} />
		);

		const usingPlanner = VIDEOS.USING_PLANNER.map((video, index) => 
			<Tile title={video.title} description={video.description} url={video.url} img={video.img} key={index} onClick={(event) => this.props.handleTileClick(event)} />
		);

		const quickTips = VIDEOS.QUICK_TIPS.map((video, index) => 
			<Tile title={video.title} description={video.description} url={video.url} img={video.img} key={index} onClick={(event) => this.props.handleTileClick(event)} />
		);

		const prelude = VIDEOS.PRELUDE.map((video, index) => 
			<Tile title={video.title} description={video.description} url={video.url} img={video.img} key={index} onClick={(event) => this.props.handleTileClick(event)} />
		);

		const settings = {
			dots: false,
			infinite: false,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1
					}
				}
			]
		}

		return (
			<section className="library">		
				<div className="wrapper">
					<div className="playlist">
						<h3>Home Page Overview</h3>
						<div className="tiles active" id="home-page-overview">
							<Slider {...settings}>
								{homePageOverview}
							</Slider>
						</div>
					</div>

					<div className="playlist">
						<h3>Touring the Tabs</h3>
						<div className="tiles" id="touring-tabs">
							<Slider {...settings}>
								{touringTabs}
							</Slider>
						</div>
					</div>

					<div className="playlist">
						<h3>Using the Planner</h3>
						<div className="tiles" id="using-planner">
							<Slider {...settings}>
								{usingPlanner}
							</Slider>
						</div>
					</div>

					<div className="playlist">
						<h3>Icon Quick Tips</h3>
						<div className="tiles" id="quick-tips">
							<Slider {...settings}>
								{quickTips}
							</Slider>
						</div>
					</div>


					<div className="playlist" style={{ 'borderBottom': 'none' }}>
						<h3>Prelude Music Planner OnDemand Training Video Playlist</h3>
						<p>Prelude Music Planner is a state-of-the-art, online, music acquisition and management platform that works in harmony with sundaysandseasons.com, but is uniquely supportive of church musicians. Using the Prelude application, subscribers can find, buy, and manage music appropriate to every Sunday, festival, and commemoration of the Church Year.</p>
						
						<p>The two videos in this playlist support each of the five key- features of the Prelude Music Planner: <em>Browse</em>, <em>Buy</em>, <em>Build</em>, <em>Blend</em>, and <em>Share</em>.</p>
						
						<div className="tiles" id="prelude">
							<Slider {...settings}>
								{prelude}
							</Slider>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

const Tile = (props) => {
	return (
		<div className='tile' data-url={props.url} onClick={props.onClick}>
			<div className="image">
				<img src={props.img} alt={props.title} />
			</div>
			<div className="content">
				<h4>{props.title}</h4>
				<div dangerouslySetInnerHTML={{__html: props.description}} />
			</div>
		</div>
	);
};

export default Library;